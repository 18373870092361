import React from 'react'
import { Helmet } from "react-helmet";
import NavBar from '../components/NavBar'
import Footer from '../components/Footer'
import classes3 from '../assets/img/classes-3.jpg'
import classes4 from '../assets/img/classes-4.jpg'
import classes5 from '../assets/img/classes-5.jpg'
import appointment from '../assets/img/appointment.jpg'

const Fees = () => {
  return (
    <>
    <Helmet>
        <meta charSet="utf-8" />
        <title>Fees Structure Pitter Patter Academy</title>
        <link rel="canonical" href="https://pitterpatteracademy.info/fees" />
        <meta property="og:title" content="Fees Structure Pitter Patter Academy" />
        <meta property="og:description"
          content="Discover a nurturing environment where every child's journey begins.Our holistic approach to early childhood education fosters a love for learning, promotes social and emotional development, and lays a strong foundation for future success. Explore our enriching curriculum, dedicated educators, and state-of-the-art facilities today." />
        <meta property="og:image"
          content="%PUBLIC_URL%/img/logo1.jpg" />
        <meta property="og:url" content="https://pitterpatteracademy.info/fees" />
        <meta name="keywords" content="pitter patter academy entebbe kawuku" />
        <link href="https://fonts.googleapis.com/css2?family=Outfit:wght@100;200;400;700&display=swap" rel="stylesheet" />

      </Helmet>
    <div class="bg-white p-0">
      <NavBar />
      {/* <!-- Page Header End --> */}
      <div class="py-5 page-header position-relative mb-5">
        <div class="container py-5">
          <h1 class="display-2 text-white animated slideInDown mb-4">Fees</h1>
          <nav aria-label="breadcrumb animated slideInDown">
            <ol class="breadcrumb">
              <li class="breadcrumb-item"><a href="#">Home</a></li>
              <li class="breadcrumb-item text-white active" aria-current="page">Fees Structure</li>
            </ol>
          </nav>
        </div>
      </div>
      {/* <!-- Page Header End --> */}

      {/* <!-- Classes Start --> */}
      <div class="container-xxl py-5">
        <div class="container">
          <div class="text-center mx-auto mb-5 wow fadeInUp" data-wow-delay="0.1s" style={{ maxWidth: 600 }}>
            <h1 class="mb-3">Fees Structure</h1>
            <p>The total amount for a full school uniform including a sweater, trouser/skirt, shirt/blouse, sportswear & stockings is UGX 150,000 only</p>
            <p>Admission fee for every new student is UGX 50,000 and Development fee is UGX 30,000</p>
          </div>
          <div class="row g-4">
            <div class="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.1s">
              <div class="classes-item">
                <div class="bg-light rounded-circle w-75 mx-auto p-3">
                  <img class="img-fluid rounded-circle" src="img/classes-1.jpg" alt="" />
                </div>
                <div class="bg-light rounded p-4 pt-5 mt-n5">
                  <a class="d-block text-center h3 mt-3 mb-4" href="">Day Care</a>
                  <div class="d-flex align-items-center justify-content-center mb-4">
                    {/* <div class="d-flex align-items-center">
                      <img class="rounded-circle flex-shrink-0" src="img/user.jpg" alt="" style={{ width: 45, height: 45 }} />
                      <div class="ms-3">
                        <h6 class="text-primary mb-1">Jhon Doe</h6>
                        <small>Teacher</small>
                      </div>
                    </div> */}
                    <span class="bg-primary text-white rounded-pill py-2 px-3" href="">UGX 20,000</span>
                  </div>
                  <div class="row g-1">
                    <div class="col-4">
                      <div class="border-top border-3 border-primary pt-2">
                        <h6 class="text-primary mb-1">Age:</h6>
                        <small>3-5 Years</small>
                      </div>
                    </div>
                    <div class="col-4">
                      <div class="border-top border-3 border-success pt-2">
                        <h6 class="text-success mb-1">Time:</h6>
                        <small>Daily</small>
                      </div>
                    </div>
                    <div class="col-4">
                      <div class="border-top border-3 border-warning pt-2">
                        <h6 class="text-warning mb-1">Capacity:</h6>
                        <small>30 Kids</small>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.5s">
              <div class="classes-item">
                <div class="bg-light rounded-circle w-75 mx-auto p-3">
                  <img class="img-fluid rounded-circle" src="img/classes-1.jpg" alt="" />
                </div>
                <div class="bg-light rounded p-4 pt-5 mt-n5">
                  <a class="d-block text-center h3 mt-3 mb-4" href="">Day Care</a>
                  <div class="d-flex align-items-center justify-content-center mb-4">
                    {/* <div class="d-flex align-items-center">
                      <img class="rounded-circle flex-shrink-0" src="img/user.jpg" alt="" style={{ width: 45, height: 45 }} />
                      <div class="ms-3">
                        <h6 class="text-primary mb-1">Jhon Doe</h6>
                        <small>Teacher</small>
                      </div>
                    </div> */}
                    <span class="bg-primary text-white rounded-pill py-2 px-3" href="">UGX 105,000</span>
                  </div>
                  <div class="row g-1">
                    <div class="col-4">
                      <div class="border-top border-3 border-primary pt-2">
                        <h6 class="text-primary mb-1">Age:</h6>
                        <small>3-5 Years</small>
                      </div>
                    </div>
                    <div class="col-4">
                      <div class="border-top border-3 border-success pt-2">
                        <h6 class="text-success mb-1">Time:</h6>
                        <small>Weekly</small>
                      </div>
                    </div>
                    <div class="col-4">
                      <div class="border-top border-3 border-warning pt-2">
                        <h6 class="text-warning mb-1">Capacity:</h6>
                        <small>30 Kids</small>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.5s">
              <div class="classes-item">
                <div class="bg-light rounded-circle w-75 mx-auto p-3">
                  <img class="img-fluid rounded-circle" src="img/classes-1.jpg" alt="" />
                </div>
                <div class="bg-light rounded p-4 pt-5 mt-n5">
                  <a class="d-block text-center h3 mt-3 mb-4" href="">Day Care</a>
                  <div class="d-flex align-items-center justify-content-center mb-4">
                    {/* <div class="d-flex align-items-center">
                      <img class="rounded-circle flex-shrink-0" src="img/user.jpg" alt="" style={{ width: 45, height: 45 }} />
                      <div class="ms-3">
                        <h6 class="text-primary mb-1">Jhon Doe</h6>
                        <small>Teacher</small>
                      </div>
                    </div> */}
                    <span class="bg-primary text-white rounded-pill py-2 px-3" href="">UGX 450,000</span>
                  </div>
                  <div class="row g-1">
                    <div class="col-4">
                      <div class="border-top border-3 border-primary pt-2">
                        <h6 class="text-primary mb-1">Age:</h6>
                        <small>3-5 Years</small>
                      </div>
                    </div>
                    <div class="col-4">
                      <div class="border-top border-3 border-success pt-2">
                        <h6 class="text-success mb-1">Time:</h6>
                        <small>Monthly</small>
                      </div>
                    </div>
                    <div class="col-4">
                      <div class="border-top border-3 border-warning pt-2">
                        <h6 class="text-warning mb-1">Capacity:</h6>
                        <small>30 Kids</small>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.3s">
              <div class="classes-item">
                <div class="bg-light rounded-circle w-75 mx-auto p-3">
                  <img class="img-fluid rounded-circle" src="img/classes-2.jpg" alt="" />
                </div>
                <div class="bg-light rounded p-4 pt-5 mt-n5">
                  <a class="d-block text-center h3 mt-3 mb-4" href="">Nusery</a>
                  <div class="d-flex align-items-center justify-content-center mb-4">
                    {/* <div class="d-flex align-items-center">
                      <img class="rounded-circle flex-shrink-0" src="img/user.jpg" alt="" style={{ width: 45, height: 45 }} />
                      <div class="ms-3">
                        <h6 class="text-primary mb-1">Jhon Doe</h6>
                        <small>Teacher</small>
                      </div>
                    </div> */}
                    <span class="bg-primary text-white rounded-pill py-2 px-3" href="">UGX 550,000</span>
                  </div>
                  <div class="row g-1">
                    <div class="col-4">
                      <div class="border-top border-3 border-primary pt-2">
                        <h6 class="text-primary mb-1">Age:</h6>
                        <small>3-5 Years</small>
                      </div>
                    </div>
                    <div class="col-4">
                      <div class="border-top border-3 border-success pt-2">
                        <h6 class="text-success mb-1">Time:</h6>
                        <small>Half Day</small>
                      </div>
                    </div>
                    <div class="col-4">
                      <div class="border-top border-3 border-warning pt-2">
                        <h6 class="text-warning mb-1">Capacity:</h6>
                        <small>30 Kids</small>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.3s">
              <div class="classes-item">
                <div class="bg-light rounded-circle w-75 mx-auto p-3">
                  <img class="img-fluid rounded-circle" src="img/classes-2.jpg" alt="" />
                </div>
                <div class="bg-light rounded p-4 pt-5 mt-n5">
                  <a class="d-block text-center h3 mt-3 mb-4" href="">Nusery</a>
                  <div class="d-flex align-items-center justify-content-center mb-4">
                    {/* <div class="d-flex align-items-center">
                      <img class="rounded-circle flex-shrink-0" src="img/user.jpg" alt="" style={{ width: 45, height: 45 }} />
                      <div class="ms-3">
                        <h6 class="text-primary mb-1">Jhon Doe</h6>
                        <small>Teacher</small>
                      </div>
                    </div> */}
                    <span class="bg-primary text-white rounded-pill py-2 px-3" href="">UGX 650,000</span>
                  </div>
                  <div class="row g-1">
                    <div class="col-4">
                      <div class="border-top border-3 border-primary pt-2">
                        <h6 class="text-primary mb-1">Age:</h6>
                        <small>3-5 Years</small>
                      </div>
                    </div>
                    <div class="col-4">
                      <div class="border-top border-3 border-success pt-2">
                        <h6 class="text-success mb-1">Time:</h6>
                        <small>Full Day</small>
                      </div>
                    </div>
                    <div class="col-4">
                      <div class="border-top border-3 border-warning pt-2">
                        <h6 class="text-warning mb-1">Capacity:</h6>
                        <small>30 Kids</small>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.5s">
              <div class="classes-item">
                <div class="bg-light rounded-circle w-75 mx-auto p-3">
                  <img class="img-fluid rounded-circle" src={classes3} alt="" />
                </div>
                <div class="bg-light rounded p-4 pt-5 mt-n5">
                  <a class="d-block text-center h3 mt-3 mb-4" href="">Primary One</a>
                  <div class="d-flex align-items-center justify-content-center mb-4">
                    {/* <div class="d-flex align-items-center">
                      <img class="rounded-circle flex-shrink-0" src="img/user.jpg" alt="" style={{ width: 45, height: 45 }} />
                      <div class="ms-3">
                        <h6 class="text-primary mb-1">Jhon Doe</h6>
                        <small>Teacher</small>
                      </div>
                    </div> */}
                    <span class="bg-primary text-white rounded-pill py-2 px-3" href="">UGX 650,000</span>
                  </div>
                  <div class="row g-1">
                    <div class="col-4">
                      <div class="border-top border-3 border-primary pt-2">
                        <h6 class="text-primary mb-1">Age:</h6>
                        <small>3-5 Years</small>
                      </div>
                    </div>
                    <div class="col-4">
                      <div class="border-top border-3 border-success pt-2">
                        <h6 class="text-success mb-1">Time:</h6>
                        <small>Full Day</small>
                      </div>
                    </div>
                    <div class="col-4">
                      <div class="border-top border-3 border-warning pt-2">
                        <h6 class="text-warning mb-1">Capacity:</h6>
                        <small>30 Kids</small>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.1s">
              <div class="classes-item">
                <div class="bg-light rounded-circle w-75 mx-auto p-3">
                  <img class="img-fluid rounded-circle" src={classes4} alt="" />
                </div>
                <div class="bg-light rounded p-4 pt-5 mt-n5">
                  <a class="d-block text-center h3 mt-3 mb-4" href="">Primary Two</a>
                  <div class="d-flex align-items-center justify-content-center mb-4">
                    {/* <div class="d-flex align-items-center">
                      <img class="rounded-circle flex-shrink-0" src="img/user.jpg" alt="" style={{ width: 45, height: 45 }} />
                      <div class="ms-3">
                        <h6 class="text-primary mb-1">Jhon Doe</h6>
                        <small>Teacher</small>
                      </div>
                    </div> */}
                    <span class="bg-primary text-white rounded-pill py-2 px-3" href="">UGX 650,000</span>
                  </div>
                  <div class="row g-1">
                    <div class="col-4">
                      <div class="border-top border-3 border-primary pt-2">
                        <h6 class="text-primary mb-1">Age:</h6>
                        <small>3-5 Years</small>
                      </div>
                    </div>
                    <div class="col-4">
                      <div class="border-top border-3 border-success pt-2">
                        <h6 class="text-success mb-1">Time:</h6>
                        <small>Full Day</small>
                      </div>
                    </div>
                    <div class="col-4">
                      <div class="border-top border-3 border-warning pt-2">
                        <h6 class="text-warning mb-1">Capacity:</h6>
                        <small>30 Kids</small>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.3s">
              <div class="classes-item">
                <div class="bg-light rounded-circle w-75 mx-auto p-3">
                  <img class="img-fluid rounded-circle" src={classes5} alt="" />
                </div>
                <div class="bg-light rounded p-4 pt-5 mt-n5">
                  <a class="d-block text-center h3 mt-3 mb-4" href="">Primary Three</a>
                  <div class="d-flex align-items-center justify-content-center mb-4">
                    {/* <div class="d-flex align-items-center">
                      <img class="rounded-circle flex-shrink-0" src="img/user.jpg" alt="" style={{ width: 45, height: 45 }} />
                      <div class="ms-3">
                        <h6 class="text-primary mb-1">Jhon Doe</h6>
                        <small>Teacher</small>
                      </div>
                    </div> */}
                    <span class="bg-primary text-white rounded-pill py-2 px-3" href="">UGX 650,000</span>
                  </div>
                  <div class="row g-1">
                    <div class="col-4">
                      <div class="border-top border-3 border-primary pt-2">
                        <h6 class="text-primary mb-1">Age:</h6>
                        <small>3-5 Years</small>
                      </div>
                    </div>
                    <div class="col-4">
                      <div class="border-top border-3 border-success pt-2">
                        <h6 class="text-success mb-1">Time:</h6>
                        <small>Full Day</small>
                      </div>
                    </div>
                    <div class="col-4">
                      <div class="border-top border-3 border-warning pt-2">
                        <h6 class="text-warning mb-1">Capacity:</h6>
                        <small>30 Kids</small>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <!-- Classes End --> */}

      {/* <!-- Appointment Start --> */}
      <div class="container-xxl py-5">
        <div class="container">
          <div class="bg-light rounded">
            <div class="row g-0">
              <div class="col-lg-6 wow fadeIn" data-wow-delay="0.1s">
                <div class="h-100 d-flex flex-column justify-content-center p-5">
                  <h1 class="mb-4">Make Appointment</h1>
                  <form>
                    <div class="row g-3">
                      <div class="col-sm-6">
                        <div class="form-floating">
                          <input type="text" class="form-control border-0" id="gname" placeholder="Gurdian Name" />
                          <label for="gname">Gurdian Name</label>
                        </div>
                      </div>
                      <div class="col-sm-6">
                        <div class="form-floating">
                          <input type="email" class="form-control border-0" id="gmail" placeholder="Gurdian Email" />
                          <label for="gmail">Gurdian Email</label>
                        </div>
                      </div>
                      <div class="col-sm-6">
                        <div class="form-floating">
                          <input type="text" class="form-control border-0" id="cname" placeholder="Child Name" />
                          <label for="cname">Child Name</label>
                        </div>
                      </div>
                      <div class="col-sm-6">
                        <div class="form-floating">
                          <input type="text" class="form-control border-0" id="cage" placeholder="Child Age" />
                          <label for="cage">Child Age</label>
                        </div>
                      </div>
                      <div class="col-12">
                        <div class="form-floating">
                          <textarea class="form-control border-0" placeholder="Leave a message here" id="message" style={{ height: 100 }}></textarea>
                          <label for="message">Message</label>
                        </div>
                      </div>
                      <div class="col-12">
                        <button class="btn btn-primary w-100 py-3" type="submit">Submit</button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
              <div class="col-lg-6 wow fadeIn" data-wow-delay="0.5s" style={{ minHeight: 400 }}>
                <div class="position-relative h-100">
                  <img class="position-absolute w-100 h-100 rounded" src={appointment} style={{ objectFit: 'cover' }} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <!-- Appointment End --> */}
      
      <Footer />
    </div>
    </>
  )
}

export default Fees
