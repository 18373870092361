import React from 'react'
import { Helmet } from "react-helmet";
import NavBar from '../components/NavBar'
import Footer from '../components/Footer'
import about1 from '../assets/img/about-1.jpg'
import about2 from '../assets/img/about-2.jpg'
import about3 from '../assets/img/about-3.jpg'
import calltoaction from '../assets/img/call-to-action.jpg'

const About = () => {
  return (
    <>
    <Helmet>
        <meta charSet="utf-8" />
        <title>About Pitter Patter Academy</title>
        <link rel="canonical" href="https://pitterpatteracademy.info/about" />
        <meta property="og:title" content="About Pitter Patter Academy" />
        <meta property="og:description"
          content="Discover a nurturing environment where every child's journey begins.Our holistic approach to early childhood education fosters a love for learning, promotes social and emotional development, and lays a strong foundation for future success. Explore our enriching curriculum, dedicated educators, and state-of-the-art facilities today." />
        <meta property="og:image"
          content="%PUBLIC_URL%/img/logo1.jpg" />
        <meta property="og:url" content="https://pitterpatteracademy.info/about" />
        <meta name="keywords" content="pitter patter academy entebbe kawuku" />
        <link href="https://fonts.googleapis.com/css2?family=Outfit:wght@100;200;400;700&display=swap" rel="stylesheet" />

      </Helmet>
      
    <div class="bg-white p-0">
      <NavBar />
      {/* <!-- Page Header End --> */}
      <div class="py-5 page-header position-relative mb-5">
        <div class="container py-5">
          <h1 class="display-2 text-white animated slideInDown mb-4">About Us</h1>
          <nav aria-label="breadcrumb animated slideInDown">
            <ol class="breadcrumb">
              <li class="breadcrumb-item"><a href="#">Home</a></li>
              <li class="breadcrumb-item text-white active" aria-current="page">About Us</li>
            </ol>
          </nav>
        </div>
      </div>
      {/* <!-- Page Header End --> */}

      {/* <!-- About Start --> */}
      <div class="container-xxl py-5">
        <div class="container">
          <div class="row g-5 align-items-center">
            <div class="col-lg-6 wow fadeInUp" data-wow-delay="0.1s">
              <h1 class="mb-4">Welcome to Pitter Patter Academy - Where Every Child's Journey Begins</h1>
              <p>At Pitter Patter Academy, we believe that every child deserves a nurturing and enriching environment to begin their educational journey. With a focus on holistic development, we provide a supportive and stimulating learning environment where children can thrive and reach their full potential.</p>
              <h3>Our Philosophy</h3>
              <p class="mb-4">At the heart of our philosophy is the belief that each child is unique and capable of greatness. We embrace diversity and celebrate the individual strengths and talents of every child. Our approach to early childhood education is child-centered, ensuring that each child's needs, interests, and learning styles are met with personalized attention and care.</p>
              <h3>Our Curriculum</h3>
              <p>Our curriculum is designed to foster a love for learning and promote development across all areas - cognitive, social, emotional, and physical. Through a blend of play-based learning, hands-on activities, and age-appropriate challenges, we ignite children's curiosity and encourage exploration. From early literacy and numeracy skills to creative expression and problem-solving, our curriculum lays a strong foundation for lifelong learning.</p>
              <h3>Our Team</h3>
              <p>Our team of dedicated educators is passionate about inspiring young minds and shaping the leaders of tomorrow. With a wealth of experience and expertise in early childhood education, our teachers create a warm and welcoming environment where children feel safe, valued, and supported. We work closely with families to ensure open communication and collaboration in each child's learning journey.</p>
              <h3>Our Facilities</h3>
              <p>Located in Kawuku, Entebbe Uganda, our state-of-the-art facilities provide a safe and engaging space for children to learn and grow. From spacious classrooms and outdoor play areas to well-equipped learning centers, every aspect of our environment is designed with children's needs in mind.</p>
              <h3>Our Community</h3>
              <p>At Pitter Patter Academy, we recognize the importance of community and collaboration in children's development. We foster strong partnerships with parents, families, and the wider community to create a supportive network that enhances children's learning experiences both inside and outside the classroom.</p>
              <h3>Join Us</h3>
              <p>We invite you to join our family and embark on an exciting educational journey with us. Whether you're looking for a nurturing environment for your child to thrive or seeking a rewarding career in early childhood education, we welcome you to explore all that Pitter Patter Academy has to offer.</p>
              <p>Ready to learn more? Contact us today to schedule a tour and discover why Pitter Patter Academy is the perfect place for your child's early years education.</p>
              <div class="row g-4 align-items-center">
                <div class="col-sm-6">
                  {/* <a class="btn btn-primary rounded-pill py-3 px-5" href="">Read More</a> */}
                </div>
                <div class="col-sm-6">
                  <div class="d-flex align-items-center">
                    <img class="rounded-circle flex-shrink-0" src="img/user.jpg" alt="" style={{ width: 45, height: 45 }} />
                    <div class="ms-3">
                      <h6 class="text-primary mb-1">Doreen Abwola</h6>
                      <small>CEO & Founder</small>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-lg-6 about-img wow fadeInUp" data-wow-delay="0.5s">
              <div class="row">
                <div class="col-12 text-center">
                  <img class="img-fluid w-75 rounded-circle bg-light p-3" src={about1} alt="" />
                </div>
                <div class="col-6 text-start" style={{ marginTop: -150 }}>
                  <img class="img-fluid w-100 rounded-circle bg-light p-3" src={about2} alt="" />
                </div>
                <div class="col-6 text-end" style={{ marginTop: -150 }}>
                  <img class="img-fluid w-100 rounded-circle bg-light p-3" src={about3} alt="" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <!-- About End --> */}

      {/* <!-- Call To Action Start --> */}
      <div class="container-xxl py-5">
        <div class="container">
          <div class="bg-light rounded">
            <div class="row g-0">
              <div class="col-lg-6 wow fadeIn" data-wow-delay="0.1s" style={{ minHeight: 400 }}>
                <div class="position-relative h-100">
                  <img class="position-absolute w-100 h-100 rounded" src={calltoaction} style={{ objectFit: 'cover' }} />
                </div>
              </div>
              <div class="col-lg-6 wow fadeIn" data-wow-delay="0.5s">
                <div class="h-100 d-flex flex-column justify-content-center p-5">
                  <h1 class="mb-4">Become A Member of our community</h1>
                  <p class="mb-4">
                    Not only are gaurdians welcome, neighbours, well-wishers and their pets😁 can join our whatsapp channel. inachukua jamii kumlea mtoto
                  </p>
                  <a class="btn btn-primary py-3 px-5" href="">Join Now<i class="fa fa-arrow-right ms-2"></i></a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <!-- Call To Action End --> */}

      {/* <!-- Team Start --> */}
      {/* <div class="container-xxl py-5">
        <div class="container">
          <div class="text-center mx-auto mb-5 wow fadeInUp" data-wow-delay="0.1s" style={{ maxWidth: 600 }}>
            <h1 class="mb-3">Teaching Stuff</h1>
            <p>Eirmod sed ipsum dolor sit rebum labore magna erat. Tempor ut dolore lorem kasd vero ipsum sit
              eirmod sit. Ipsum diam justo sed rebum vero dolor duo.</p>
          </div>
          <div class="row g-4">
            <div class="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.1s">
              <div class="team-item position-relative">
                <img class="img-fluid rounded-circle w-75" src="img/team-1.jpg" alt="" />
                <div class="team-text">
                  <h3>Full Name</h3>
                  <p>Designation</p>
                  <div class="d-flex align-items-center">
                    <a class="btn btn-square btn-primary mx-1" href=""><i class="fab fa-facebook-f"></i></a>
                    <a class="btn btn-square btn-primary  mx-1" href=""><i class="fab fa-twitter"></i></a>
                    <a class="btn btn-square btn-primary  mx-1" href=""><i class="fab fa-instagram"></i></a>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.3s">
              <div class="team-item position-relative">
                <img class="img-fluid rounded-circle w-75" src="img/team-2.jpg" alt="" />
                <div class="team-text">
                  <h3>Full Name</h3>
                  <p>Designation</p>
                  <div class="d-flex align-items-center">
                    <a class="btn btn-square btn-primary mx-1" href=""><i class="fab fa-facebook-f"></i></a>
                    <a class="btn btn-square btn-primary  mx-1" href=""><i class="fab fa-twitter"></i></a>
                    <a class="btn btn-square btn-primary  mx-1" href=""><i class="fab fa-instagram"></i></a>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.5s">
              <div class="team-item position-relative">
                <img class="img-fluid rounded-circle w-75" src="img/team-3.jpg" alt="" />
                <div class="team-text">
                  <h3>Full Name</h3>
                  <p>Designation</p>
                  <div class="d-flex align-items-center">
                    <a class="btn btn-square btn-primary mx-1" href=""><i class="fab fa-facebook-f"></i></a>
                    <a class="btn btn-square btn-primary  mx-1" href=""><i class="fab fa-twitter"></i></a>
                    <a class="btn btn-square btn-primary  mx-1" href=""><i class="fab fa-instagram"></i></a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div> */}
      {/* <!-- Team End --> */}
      

      <Footer />
    </div>
    </>
  )
}

export default About
