import React from 'react'
import { Link, NavLink } from 'react-router-dom';

const NavBar = () => {
  return (
    <nav class="navbar navbar-expand-lg bg-white navbar-light sticky-top px-4 px-lg-5 py-lg-0">
      <Link to="/" href="index.html" class="navbar-brand">
        <h1 class="m-0 text-primary"><i class="fa fa-book-reader me-3"></i>Pitter Patter Academy</h1>
      </Link>
      <button type="button" class="navbar-toggler" data-bs-toggle="collapse" data-bs-target="#navbarCollapse">
        <span class="navbar-toggler-icon"></span>
      </button>
      <div class="collapse navbar-collapse" id="navbarCollapse">
        <div class="navbar-nav mx-auto">
          <NavLink to="/" className={({ isActive, isPending }) => isActive ? "nav-item nav-link active" : "nav-item nav-link"}>Home</NavLink>
          <NavLink to="/about" className={({ isActive, isPending }) => isActive ? "nav-item nav-link active" : "nav-item nav-link"}>About Us</NavLink>
          <NavLink to="/fees" className={({ isActive, isPending }) => isActive ? "nav-item nav-link active" : "nav-item nav-link"}>Fees</NavLink>
          <NavLink to="/contact" className={({ isActive, isPending }) => isActive ? "nav-item nav-link active" : "nav-item nav-link"}>Contact Us</NavLink>
          <NavLink to="/gallery" className={({ isActive, isPending }) => isActive ? "nav-item nav-link active" : "nav-item nav-link"}>Gallery</NavLink>
          
          {/* <div class="nav-item dropdown">
            <a href="#" class="nav-link dropdown-toggle" data-bs-toggle="dropdown">Pages</a>
            <div class="dropdown-menu rounded-0 rounded-bottom border-0 shadow-sm m-0">
              <a href="facility.html" class="dropdown-item">School Facilities</a>
              <a href="team.html" class="dropdown-item">Popular Teachers</a>
              <a href="call-to-action.html" class="dropdown-item">Become A Teachers</a>
              <a href="appointment.html" class="dropdown-item">Make Appointment</a>
              <a href="testimonial.html" class="dropdown-item">Testimonial</a>
              <a href="404.html" class="dropdown-item">404 Error</a>
            </div>
          </div> */}
        </div>
        <NavLink to="/join" className={({ isActive, isPending }) => isActive ? "btn btn-primary rounded-pill px-3 d-none d-lg-block active" : "btn btn-primary rounded-pill px-3 d-none d-lg-block"}>Join Us<i class="fa fa-arrow-right ms-3"></i></NavLink>
      </div>
    </nav>
  )
}

export default NavBar
