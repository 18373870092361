import React from 'react'
import { Helmet } from "react-helmet";
import NavBar from '../components/NavBar'
import Footer from '../components/Footer'

const Contact = () => {
  return (
    <>
    <Helmet>
        <meta charSet="utf-8" />
        <title>Contact Pitter Patter Academy</title>
        <link rel="canonical" href="https://pitterpatteracademy.info/contact" />
        <meta property="og:title" content="Contact Pitter Patter Academy" />
        <meta property="og:description"
          content="Discover a nurturing environment where every child's journey begins.Our holistic approach to early childhood education fosters a love for learning, promotes social and emotional development, and lays a strong foundation for future success. Explore our enriching curriculum, dedicated educators, and state-of-the-art facilities today." />
        <meta property="og:image"
          content="%PUBLIC_URL%/img/logo1.jpg" />
        <meta property="og:url" content="https://pitterpatteracademy.info/contact" />
        <meta name="keywords" content="pitter patter academy entebbe kawuku" />
        <link href="https://fonts.googleapis.com/css2?family=Outfit:wght@100;200;400;700&display=swap" rel="stylesheet" />

      </Helmet>
    <div class="bg-white p-0">
      <NavBar />
      {/* <!-- Page Header End --> */}
      <div class="py-5 page-header position-relative mb-5">
        <div class="container py-5">
          <h1 class="display-2 text-white animated slideInDown mb-4">Contact Us</h1>
          <nav aria-label="breadcrumb animated slideInDown">
            <ol class="breadcrumb">
              <li class="breadcrumb-item"><a href="#">Home</a></li>
              <li class="breadcrumb-item text-white active" aria-current="page">Contact Us</li>
            </ol>
          </nav>
        </div>
      </div>
      {/* <!-- Page Header End --> */}

      {/* <!-- Contact Start --> */}
      <div class="container-xxl py-5">
        <div class="container">
          <div class="text-center mx-auto mb-5 wow fadeInUp" data-wow-delay="0.1s" style={{maxWidth: 600}}>
            <h1 class="mb-3">Get In Touch</h1>
            <p>At Pitter Patter Academy, we're dedicated to providing exceptional early childhood education and nurturing the leaders of tomorrow. Come join us and let's embark on this journey together!</p>
          </div>
          <div class="row g-4 mb-5">
            <div class="col-md-6 col-lg-4 text-center wow fadeInUp" data-wow-delay="0.1s">
              <div class="bg-light rounded-circle d-inline-flex align-items-center justify-content-center mb-4" style={{width: 75, height: 75}}>
                <i class="fa fa-map-marker-alt fa-2x text-primary"></i>
              </div>
              <h6>Kawuku, Entebbe, UGANDA</h6>
            </div>
            <div class="col-md-6 col-lg-4 text-center wow fadeInUp" data-wow-delay="0.3s">
              <div class="bg-light rounded-circle d-inline-flex align-items-center justify-content-center mb-4" style={{width: 75, height: 75}}>
                <i class="fa fa-envelope-open fa-2x text-primary"></i>
              </div>
              <h6>contact@pitterpatteracademy.info</h6>
            </div>
            <div class="col-md-6 col-lg-4 text-center wow fadeInUp" data-wow-delay="0.5s">
              <div class="bg-light rounded-circle d-inline-flex align-items-center justify-content-center mb-4" style={{width: 75, height: 75}}>
                <i class="fa fa-phone-alt fa-2x text-primary"></i>
              </div>
              <h6>+256752 587616</h6>
            </div>
          </div>
          <div class="bg-light rounded">
            <div class="row g-0">
              <div class="col-lg-6 wow fadeIn" data-wow-delay="0.1s">
                <div class="h-100 d-flex flex-column justify-content-center p-5">
                  {/* <p class="mb-4">To purchase this website. <a href="https://wa.me/message/PIZDP2E3XEKZG1">Get in touch</a>.</p> */}
                  <form>
                    <div class="row g-3">
                      <div class="col-sm-6">
                        <div class="form-floating">
                          <input type="text" class="form-control border-0" id="name" placeholder="Your Name" />
                            <label for="name">Your Name</label>
                        </div>
                      </div>
                      <div class="col-sm-6">
                        <div class="form-floating">
                          <input type="email" class="form-control border-0" id="email" placeholder="Your Email" />
                            <label for="email">Your Email</label>
                        </div>
                      </div>
                      <div class="col-12">
                        <div class="form-floating">
                          <input type="text" class="form-control border-0" id="subject" placeholder="Subject" />
                            <label for="subject">Subject</label>
                        </div>
                      </div>
                      <div class="col-12">
                        <div class="form-floating">
                          <textarea class="form-control border-0" placeholder="Leave a message here" id="message" style={{height: 100}}></textarea>
                          <label for="message">Message</label>
                        </div>
                      </div>
                      <div class="col-12">
                        <button class="btn btn-primary w-100 py-3" type="submit">Send Message</button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
              <div class="col-lg-6 wow fadeIn" data-wow-delay="0.5s" style={{minHeight: 400}}>
                <div class="position-relative h-100">
                    <iframe class="position-relative rounded w-100 h-100" style={{minHeight: 400, border: 0}} src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3989.757383276319!2d32.14742657511568!3d0.3166666996802247!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x177d13df378702e3%3A0x4724e95ff7cbf145!2sPITTER%20PATTER%20ACADEMY%20ENTEBBE%20UGANDA!5e0!3m2!1sen!2sug!4v1714999111648!5m2!1sen!2sug" allowfullscreen="" referrerpolicy="no-referrer-when-downgrade"></iframe>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <!-- Contact End --> */}

      <Footer />
    </div>
    </>
  )
}

export default Contact
