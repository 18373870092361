import { useRouteError, Link } from "react-router-dom";
import NavBar from "../components/NavBar";
import Header from "../components/Header";
import Footer from "../components/Footer";

const Error = () => {
  const error = useRouteError();

  return (
    <div class="container-xxl bg-white p-0">
      {/* <!-- Navbar Start --> */}
      <NavBar />
      {/* <!-- Navbar End --> */}
      <Header />

      <div class="container-xxl py-5 wow fadeInUp" data-wow-delay="0.1s">
        <div class="container text-center">
          <div class="row justify-content-center">
            <div class="col-lg-6">
              <i class="bi bi-exclamation-triangle display-1 text-primary"></i>
              <h1 class="display-1">{error.status}</h1>
              <h1 class="mb-4">{error.statusText || error.message}</h1>
              <p class="mb-4">{error.data}</p>
              <Link to="/" class="btn btn-primary rounded-pill py-3 px-5">Go Back Home</Link>
            </div>
          </div>
        </div>
      </div>

      <Footer />

    </div>
  )
}

export default Error
