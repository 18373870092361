import { useState } from 'react';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import Error from './pages/Error';
import Home from './pages/Home';
import About from './pages/About';
import Fees from './pages/Fees';
import Contact from './pages/Contact';
import Gallery from './pages/Gallery';
import PrivacyPolicy from './pages/PrivacyPolicy';
import TermsNConditions from './pages/TermsNConditions';

const App = () => {
  const [data, setdata] = useState([
    {
      path: "/",
      element: <Home />,
      errorElement: <Error />,
    },
    {
      path: "/about",
      element: <About />,
    },
    {
      path: "/fees",
      element: <Fees />,
    },
    {
      path: "/contact",
      element: <Contact />,
    },
    {
      path: "/join",
      element: <Contact />,
    },
    {
      path: "/gallery",
      element: <Gallery />,
    },
    {
      path: "/terms",
      element: <TermsNConditions />,
    },
    {
      path: "/privacy",
      element: <PrivacyPolicy />,
    },
  ])

  const router = createBrowserRouter(data);

  return (
    <RouterProvider router={router} />
  );
}

export default App;
