import React from 'react'
import { Helmet } from "react-helmet";
import NavBar from '../components/NavBar'
import Footer from '../components/Footer'
import IMG_3528 from '../assets/gallery/IMG_3528.png'
import IMG_3539 from '../assets/gallery/IMG_3539.png'
import IMG_3546 from '../assets/gallery/IMG_3546.png'
import IMG_3554 from '../assets/gallery/IMG_3554.png'
import IMG_3559 from '../assets/gallery/IMG_3559.png'
import IMG_3562 from '../assets/gallery/IMG_3562.png'
import IMG_3575 from '../assets/gallery/IMG_3575.png'
import IMG_3584 from '../assets/gallery/IMG_3584.JPG'
import IMG_3589 from '../assets/gallery/IMG_3589.JPG'
import IMG_3590 from '../assets/gallery/IMG_3590.JPG'
import IMG_3595 from '../assets/gallery/IMG_3595.JPG'
import IMG_3601 from '../assets/gallery/IMG_3601.JPG'
import IMG_3605 from '../assets/gallery/IMG_3605.png'
import IMG_3607 from '../assets/gallery/IMG_3607.png'
import IMG_3615 from '../assets/gallery/IMG_3615.JPG'
import IMG_3624 from '../assets/gallery/IMG_3624.png'
import IMG_3628 from '../assets/gallery/IMG_3628.png'
import IMG_3636 from '../assets/gallery/IMG_3636.JPG'
import IMG_3639 from '../assets/gallery/IMG_3639.JPG'
import IMG_3642 from '../assets/gallery/IMG_3642.JPG'
import IMG_3646 from '../assets/gallery/IMG_3646.JPG'
import IMG_3662 from '../assets/gallery/IMG_3662.png'
import IMG_3676 from '../assets/gallery/IMG_3676.png'
import IMG_3686 from '../assets/gallery/IMG_3686.png'
import IMG_3691 from '../assets/gallery/IMG_3691.JPG'
import IMG_3695 from '../assets/gallery/IMG_3695.JPG'
import IMG_3696 from '../assets/gallery/IMG_3696.JPG'
import IMG_3712 from '../assets/gallery/IMG_3712.JPG'
import IMG_3717 from '../assets/gallery/IMG_3717.JPG'
import IMG_3724 from '../assets/gallery/IMG_3724.png'
import IMG_3728 from '../assets/gallery/IMG_3728.JPG'
import IMG_3738 from '../assets/gallery/IMG_3738.png'
import IMG_3743 from '../assets/gallery/IMG_3743.png'
import IMG_3748 from '../assets/gallery/IMG_3748.png'
import IMG_3756 from '../assets/gallery/IMG_3756.png'
import IMG_3760 from '../assets/gallery/IMG_3760.png'
import IMG_3763 from '../assets/gallery/IMG_3763.png'
import IMG_3770 from '../assets/gallery/IMG_3770.png'
import IMG_3775 from '../assets/gallery/IMG_3775.png'
import IMG_3788 from '../assets/gallery/IMG_3788.png'
import IMG_3792 from '../assets/gallery/IMG_3792.png'
import IMG_3795 from '../assets/gallery/IMG_3795.png'
import IMG_3799 from '../assets/gallery/IMG_3799.png'
import IMG_3802 from '../assets/gallery/IMG_3802.JPG'
import IMG_3803 from '../assets/gallery/IMG_3803.JPG'
import IMG_3804 from '../assets/gallery/IMG_3804.JPG'
import IMG_3810 from '../assets/gallery/IMG_3810.png'
import IMG_3820 from '../assets/gallery/IMG_3820.JPG'

const Gallery = () => {
  return (
    <>
    <Helmet>
        <meta charSet="utf-8" />
        <title>Gallery Pitter Patter Academy</title>
        <link rel="canonical" href="https://pitterpatteracademy.info/gallery" />
        <meta property="og:title" content="Gallery Pitter Patter Academy" />
        <meta property="og:description"
          content="Discover a nurturing environment where every child's journey begins.Our holistic approach to early childhood education fosters a love for learning, promotes social and emotional development, and lays a strong foundation for future success. Explore our enriching curriculum, dedicated educators, and state-of-the-art facilities today." />
        <meta property="og:image"
          content="%PUBLIC_URL%/img/logo1.jpg" />
        <meta property="og:url" content="https://pitterpatteracademy.info/gallery" />
        <meta name="keywords" content="pitter patter academy entebbe kawuku" />
        <link href="https://fonts.googleapis.com/css2?family=Outfit:wght@100;200;400;700&display=swap" rel="stylesheet" />

      </Helmet>
    <div class="bg-white p-0">
      <NavBar />
      {/* <!-- Page Header End --> */}
      <div class="py-5 page-header position-relative mb-5">
        <div class="container py-5">
          <h1 class="display-2 text-white animated slideInDown mb-4">Gallery</h1>
          <nav aria-label="breadcrumb animated slideInDown">
            <ol class="breadcrumb">
              <li class="breadcrumb-item"><a href="#">Home</a></li>
              <li class="breadcrumb-item text-white active" aria-current="page">School Gallery</li>
            </ol>
          </nav>
        </div>
      </div>
      {/* <!-- Page Header End --> */}

      <div class="container-xxl py-5">
        <div class="container">
          <div class="text-center mx-auto mb-5 wow fadeInUp" data-wow-delay="0.1s" style={{ maxWidth: 600 }}>
            <h1 class="mb-3">Our memories</h1>
            <p>Welcome to our school's photo gallery, where you can browse through captivating moments captured during various events, activities, and special occasions at our academy</p>
          </div>
          <div class="row g-4">
            <div class="col-lg-4 col-md-12 mb-4 mb-lg-0">
              <img
                src={IMG_3528}
                class="w-100 shadow-1-strong rounded mb-4"
                alt="Boat on Calm Water"
              />

              <img
                src={IMG_3539}
                class="w-100 shadow-1-strong rounded mb-4"
                alt="Wintry Mountain Landscape"
              />
            </div>

            <div class="col-lg-4 mb-4 mb-lg-0">
              <img
                src={IMG_3546}
                class="w-100 shadow-1-strong rounded mb-4"
                alt="Mountains in the Clouds"
              />

              <img
                src={IMG_3554}
                class="w-100 shadow-1-strong rounded mb-4"
                alt="Boat on Calm Water"
              />
            </div>

            <div class="col-lg-4 mb-4 mb-lg-0">
              <img
                src={IMG_3559}
                class="w-100 shadow-1-strong rounded mb-4"
                alt="Waves at Sea"
              />

              <img
                src={IMG_3562}
                class="w-100 shadow-1-strong rounded mb-4"
                alt="Yosemite National Park"
              />
            </div>
            <div class="col-lg-4 mb-4 mb-lg-0">
              <img
                src={IMG_3575}
                class="w-100 shadow-1-strong rounded mb-4"
                alt="Waves at Sea"
              />

              <img
                src={IMG_3584}
                class="w-100 shadow-1-strong rounded mb-4"
                alt="Yosemite National Park"
              />
            </div>
            <div class="col-lg-4 mb-4 mb-lg-0">
              <img
                src={IMG_3589}
                class="w-100 shadow-1-strong rounded mb-4"
                alt="Waves at Sea"
              />

              <img
                src={IMG_3590}
                class="w-100 shadow-1-strong rounded mb-4"
                alt="Yosemite National Park"
              />
            </div>
            <div class="col-lg-4 mb-4 mb-lg-0">
              <img
                src={IMG_3595}
                class="w-100 shadow-1-strong rounded mb-4"
                alt="Waves at Sea"
              />

              <img
                src={IMG_3601}
                class="w-100 shadow-1-strong rounded mb-4"
                alt="Yosemite National Park"
              />
            </div>
            <div class="col-lg-4 mb-4 mb-lg-0">
              <img
                src={IMG_3605}
                class="w-100 shadow-1-strong rounded mb-4"
                alt="Waves at Sea"
              />

              <img
                src={IMG_3607}
                class="w-100 shadow-1-strong rounded mb-4"
                alt="Yosemite National Park"
              />
            </div>
            <div class="col-lg-4 mb-4 mb-lg-0">
              <img
                src={IMG_3615}
                class="w-100 shadow-1-strong rounded mb-4"
                alt="Waves at Sea"
              />

              <img
                src={IMG_3624}
                class="w-100 shadow-1-strong rounded mb-4"
                alt="Yosemite National Park"
              />
            </div>
            <div class="col-lg-4 mb-4 mb-lg-0">
              <img
                src={IMG_3628}
                class="w-100 shadow-1-strong rounded mb-4"
                alt="Waves at Sea"
              />

              <img
                src={IMG_3636}
                class="w-100 shadow-1-strong rounded mb-4"
                alt="Yosemite National Park"
              />
            </div>
            <div class="col-lg-4 mb-4 mb-lg-0">
              <img
                src={IMG_3639}
                class="w-100 shadow-1-strong rounded mb-4"
                alt="Waves at Sea"
              />

              <img
                src={IMG_3642}
                class="w-100 shadow-1-strong rounded mb-4"
                alt="Yosemite National Park"
              />
            </div>
            <div class="col-lg-4 mb-4 mb-lg-0">
              <img
                src={IMG_3646}
                class="w-100 shadow-1-strong rounded mb-4"
                alt="Waves at Sea"
              />

              <img
                src={IMG_3662}
                class="w-100 shadow-1-strong rounded mb-4"
                alt="Yosemite National Park"
              />
            </div>
            <div class="col-lg-4 mb-4 mb-lg-0">
              <img
                src={IMG_3676}
                class="w-100 shadow-1-strong rounded mb-4"
                alt="Waves at Sea"
              />

              <img
                src={IMG_3686}
                class="w-100 shadow-1-strong rounded mb-4"
                alt="Yosemite National Park"
              />
            </div>
            <div class="col-lg-4 mb-4 mb-lg-0">
              <img
                src={IMG_3691}
                class="w-100 shadow-1-strong rounded mb-4"
                alt="Waves at Sea"
              />

              <img
                src={IMG_3695}
                class="w-100 shadow-1-strong rounded mb-4"
                alt="Yosemite National Park"
              />
            </div>
            <div class="col-lg-4 mb-4 mb-lg-0">
              <img
                src={IMG_3696}
                class="w-100 shadow-1-strong rounded mb-4"
                alt="Waves at Sea"
              />

              <img
                src={IMG_3712}
                class="w-100 shadow-1-strong rounded mb-4"
                alt="Yosemite National Park"
              />
            </div>
            <div class="col-lg-4 mb-4 mb-lg-0">
              <img
                src={IMG_3717}
                class="w-100 shadow-1-strong rounded mb-4"
                alt="Waves at Sea"
              />

              <img
                src={IMG_3724}
                class="w-100 shadow-1-strong rounded mb-4"
                alt="Yosemite National Park"
              />
            </div>
            <div class="col-lg-4 mb-4 mb-lg-0">
              <img
                src={IMG_3728}
                class="w-100 shadow-1-strong rounded mb-4"
                alt="Waves at Sea"
              />

              <img
                src={IMG_3738}
                class="w-100 shadow-1-strong rounded mb-4"
                alt="Yosemite National Park"
              />
            </div>
            <div class="col-lg-4 mb-4 mb-lg-0">
              <img
                src={IMG_3743}
                class="w-100 shadow-1-strong rounded mb-4"
                alt="Waves at Sea"
              />

              <img
                src={IMG_3748}
                class="w-100 shadow-1-strong rounded mb-4"
                alt="Yosemite National Park"
              />
            </div>
            <div class="col-lg-4 mb-4 mb-lg-0">
              <img
                src={IMG_3756}
                class="w-100 shadow-1-strong rounded mb-4"
                alt="Waves at Sea"
              />

              <img
                src={IMG_3760}
                class="w-100 shadow-1-strong rounded mb-4"
                alt="Yosemite National Park"
              />
            </div>
            <div class="col-lg-4 mb-4 mb-lg-0">
              <img
                src={IMG_3763}
                class="w-100 shadow-1-strong rounded mb-4"
                alt="Waves at Sea"
              />

              <img
                src={IMG_3770}
                class="w-100 shadow-1-strong rounded mb-4"
                alt="Yosemite National Park"
              />
            </div>
            <div class="col-lg-4 mb-4 mb-lg-0">
              <img
                src={IMG_3775}
                class="w-100 shadow-1-strong rounded mb-4"
                alt="Waves at Sea"
              />

              <img
                src={IMG_3788}
                class="w-100 shadow-1-strong rounded mb-4"
                alt="Yosemite National Park"
              />
            </div>
            <div class="col-lg-4 mb-4 mb-lg-0">
              <img
                src={IMG_3792}
                class="w-100 shadow-1-strong rounded mb-4"
                alt="Waves at Sea"
              />

              <img
                src={IMG_3795}
                class="w-100 shadow-1-strong rounded mb-4"
                alt="Yosemite National Park"
              />
            </div>
            <div class="col-lg-4 mb-4 mb-lg-0">
              <img
                src={IMG_3799}
                class="w-100 shadow-1-strong rounded mb-4"
                alt="Waves at Sea"
              />

              <img
                src={IMG_3802}
                class="w-100 shadow-1-strong rounded mb-4"
                alt="Yosemite National Park"
              />
            </div>
            <div class="col-lg-4 mb-4 mb-lg-0">
              <img
                src={IMG_3803}
                class="w-100 shadow-1-strong rounded mb-4"
                alt="Waves at Sea"
              />

              <img
                src={IMG_3804}
                class="w-100 shadow-1-strong rounded mb-4"
                alt="Yosemite National Park"
              />
            </div>
            <div class="col-lg-4 mb-4 mb-lg-0">
              <img
                src={IMG_3810}
                class="w-100 shadow-1-strong rounded mb-4"
                alt="Waves at Sea"
              />

              <img
                src={IMG_3820}
                class="w-100 shadow-1-strong rounded mb-4"
                alt="Yosemite National Park"
              />
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </div>
    </>
  )
}

export default Gallery
