import React from 'react'
import { Link } from 'react-router-dom';

const Footer = () => {
  return (
    <div class="container-fluid bg-dark text-white-50 footer pt-5 mt-5 wow fadeIn" data-wow-delay="0.1s">
      <div class="container py-5">
        <div class="row g-5">
          <div class="col-lg-3 col-md-6">
            <h3 class="text-white mb-4">Get In Touch</h3>
            <p class="mb-2"><i class="fa fa-map-marker-alt me-3"></i>Kawuku, Entebbe, UGANDA</p>
            <p class="mb-2"><i class="fa fa-phone-alt me-3"></i>+256752 587616</p>
            <p class="mb-2"><i class="fa fa-envelope me-3"></i>contact@pitterpatteracademy.info</p>
            <div class="d-flex pt-2">
              <a class="btn btn-outline-light btn-social" href="#"><i class="fab fa-twitter"></i></a>
              <a class="btn btn-outline-light btn-social" href="#"><i class="fab fa-facebook-f"></i></a>
              <a class="btn btn-outline-light btn-social" href="#"><i class="fab fa-youtube"></i></a>
              <a class="btn btn-outline-light btn-social" href="#"><i class="fab fa-linkedin-in"></i></a>
            </div>
          </div>
          <div class="col-lg-3 col-md-6">
            <h3 class="text-white mb-4">Quick Links</h3>
            <Link class="btn btn-link text-white-50" to="/">Home</Link>
            <Link class="btn btn-link text-white-50" to="/about">About Us</Link>
            <Link class="btn btn-link text-white-50" to="/fees">Fees Structure</Link>
            <Link class="btn btn-link text-white-50" to="/contact">Contact Us</Link>
            <Link class="btn btn-link text-white-50" to="/privacy">Privacy Policy</Link>
            <Link class="btn btn-link text-white-50" to="/terms">Terms & Condition</Link>
          </div>
          <div class="col-lg-3 col-md-6">
            <h3 class="text-white mb-4"><Link to="/gallery">Photo Gallery</Link></h3>
            <div class="row g-2 pt-2">
              <div class="col-4">
                <img class="img-fluid rounded bg-light p-1" src="img/classes-1.jpg" alt="" />
              </div>
              <div class="col-4">
                <img class="img-fluid rounded bg-light p-1" src="img/classes-2.jpg" alt="" />
              </div>
              <div class="col-4">
                <img class="img-fluid rounded bg-light p-1" src="img/classes-3.jpg" alt="" />
              </div>
              <div class="col-4">
                <img class="img-fluid rounded bg-light p-1" src="img/classes-4.jpg" alt="" />
              </div>
              <div class="col-4">
                <img class="img-fluid rounded bg-light p-1" src="img/classes-5.jpg" alt="" />
              </div>
              <div class="col-4">
                <img class="img-fluid rounded bg-light p-1" src="img/classes-6.jpg" alt="" />
              </div>
            </div>
          </div>
          <div class="col-lg-3 col-md-6">
            <h3 class="text-white mb-4">Newsletter</h3>
            <p>Don't miss out on the latest news, events, and updates from our kindergarten! Sign up for our newsletter today and stay connected with all the exciting happenings in our vibrant learning community.</p>
            <div class="position-relative mx-auto" style={{ maxWidth: 400 }}>
              <input class="form-control bg-transparent w-100 py-3 ps-4 pe-5" type="text" placeholder="Your email" />
              <button type="button" class="btn btn-primary py-2 position-absolute top-0 end-0 mt-2 me-2">SignUp</button>
            </div>
          </div>
        </div>
      </div>
      <div class="container">
        <div class="copyright">
          <div class="row">
            <div class="col-md-6 text-center text-md-start mb-3 mb-md-0">
              &copy; <a class="border-bottom" href="#">Pitter Patter Academy</a>, All Right Reserved.
            </div>
            <div class="col-md-6 text-center text-md-end">
              <div class="footer-menu">
                <a href="">Home</a>
                <a href="">Cookies</a>
                <a href="">Help</a>
                <a href="">FQAs</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Footer
