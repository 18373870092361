import { useState } from 'react'
import corosel1 from '../assets/img/corosel1.png'
import carosel2 from '../assets/img/carosel2.png'
import about1 from '../assets/img/about-1.jpg'
import about2 from '../assets/img/about-2.jpg'
import about3 from '../assets/img/about-3.jpg'
import calltoaction from '../assets/img/call-to-action.jpg'
import classes3 from '../assets/img/classes-3.jpg'
import classes4 from '../assets/img/classes-4.jpg'
import classes5 from '../assets/img/classes-5.jpg'
import appointment from '../assets/img/appointment.jpg'
import NavBar from '../components/NavBar'
import Footer from '../components/Footer'

const Home = () => {
  return (
    <div class="bg-white p-0">

      {/* <!-- Navbar Start --> */}
      <NavBar />
      {/* <!-- Navbar End --> */}


      {/* <!-- Carousel Start --> */}
      <div class="container-fluid p-0 mb-5">
        <div class="owl-carousel header-carousel position-relative">
          <div class="owl-carousel-item position-relative">
            <img class="img-fluid" src={corosel1} alt="" />
            <div class="position-absolute top-0 start-0 w-100 h-100 d-flex align-items-center" style={{ backgroundColor: 'rgba(0, 0, 0, .2)' }}>
              <div class="container">
                <div class="row justify-content-start">
                  <div class="col-10 col-lg-8">
                    <h1 class="display-2 text-white animated slideInDown mb-4">The best day care, nursery and primary School </h1>
                    <p class="fs-5 fw-medium text-white mb-4 pb-2">Founded on pillars of meeting the education, moral ethics and generational training of Future citizens</p>
                    <a href="" class="btn btn-primary rounded-pill py-sm-3 px-sm-5 me-3 animated slideInLeft">Learn More</a>
                    <a href="" class="btn btn-dark rounded-pill py-sm-3 px-sm-5 animated slideInRight">Our Classes</a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="owl-carousel-item position-relative">
            <img class="img-fluid" src={carosel2} alt="" />
            <div class="position-absolute top-0 start-0 w-100 h-100 d-flex align-items-center" style={{ backgroundColor: 'rgba(0, 0, 0, .2)' }}>
              <div class="container">
                <div class="row justify-content-start">
                  <div class="col-10 col-lg-8">
                    <h1 class="display-2 text-white animated slideInDown mb-4">Make A Brighter Future For Your Child</h1>
                    <p class="fs-5 fw-medium text-white mb-4 pb-2">Through quality and approved training exercised by professional staff while following the Uganda national guidelines on education system.</p>
                    <a href="" class="btn btn-primary rounded-pill py-sm-3 px-sm-5 me-3 animated slideInLeft">Learn More</a>
                    <a href="" class="btn btn-dark rounded-pill py-sm-3 px-sm-5 animated slideInRight">Our Classes</a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <!-- Carousel End --> */}


      {/* <!-- Facilities Start --> */}
      <div class="container-xxl py-5">
        <div class="container">
          <div class="text-center mx-auto mb-5 wow fadeInUp" data-wow-delay="0.1s" style={{maxWidth: 600}}>
            <h1 class="mb-3">Our Values</h1>
            <p>We believe that every child is a unique individual with limitless potential, so we are committed to providing a nurturing and inclusive environment where children can explore, learn, and grow to be the best versions of themselves.</p>
          </div>
          <div class="row g-4">
            <div class="col-lg-3 col-sm-6 wow fadeInUp" data-wow-delay="0.1s">
              <div class="facility-item">
                <div class="facility-icon bg-primary">
                  <span class="bg-primary"></span>
                  <i class="fa fa-laptop fa-3x text-primary"></i>
                  <span class="bg-primary"></span>
                </div>
                <div class="facility-text bg-primary">
                  <h3 class="text-primary mb-3">Curiosity</h3>
                  <p class="mb-0">We encourage children to ask questions, investigate their surroundings, and seek out new experiences.</p>
                </div>
              </div>
            </div>
            <div class="col-lg-3 col-sm-6 wow fadeInUp" data-wow-delay="0.3s">
              <div class="facility-item">
                <div class="facility-icon bg-success">
                  <span class="bg-success"></span>
                  <i class="fa fa-people-carry fa-3x text-success"></i>
                  <span class="bg-success"></span>
                </div>
                <div class="facility-text bg-success">
                  <h3 class="text-success mb-3">Inclusivity</h3>
                  <p class="mb-0">We believe in creating a welcoming and accepting environment where every child feels valued, respected, and included.</p>
                </div>
              </div>
            </div>
            <div class="col-lg-3 col-sm-6 wow fadeInUp" data-wow-delay="0.5s">
              <div class="facility-item">
                <div class="facility-icon bg-warning">
                  <span class="bg-warning"></span>
                  <i class="fa fa-futbol fa-3x text-warning"></i>
                  <span class="bg-warning"></span>
                </div>
                <div class="facility-text bg-warning">
                  <h3 class="text-warning mb-3">Creativity</h3>
                  <p class="mb-0">We cultivate creativity through artistic endeavors, imaginative play, and open-ended activities sothat learners can think innovatively</p>
                </div>
              </div>
            </div>
            <div class="col-lg-3 col-sm-6 wow fadeInUp" data-wow-delay="0.7s">
              <div class="facility-item">
                <div class="facility-icon bg-info">
                  <span class="bg-info"></span>
                  <i class="fa fa-chalkboard-teacher fa-3x text-info"></i>
                  <span class="bg-info"></span>
                </div>
                <div class="facility-text bg-info">
                  <h3 class="text-info mb-3">Community</h3>
                  <p class="mb-0">We encourage cooperation, empathy, and kindness which creates a supportive and inclusive environment for teamwork and mutual respect among children</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <!-- Facilities End --> */}


      {/* <!-- About Start --> */}
      <div class="container-xxl py-5">
        <div class="container">
          <div class="row g-5 align-items-center">
            <div class="col-lg-6 wow fadeInUp" data-wow-delay="0.1s">
              <h1 class="mb-4">Learn More About Our Work And Our Cultural Activities</h1>
              <p>At PITTER PATTER ACADEMY Our dedicated team of educators is committed to providing a nurturing and inclusive environment where children can explore, learn, and grow at their own pace</p>
              <p class="mb-4">Our curriculum is designed to inspire curiosity, creativity, and a love for learning. Through hands-on activities, play-based learning experiences, and engaging lessons, we foster the development of essential skills such as critical thinking, problem-solving, and social-emotional competence.</p>
              
              <div class="row g-4 align-items-center">
                <div class="col-sm-6">
                  <a class="btn btn-primary rounded-pill py-3 px-5" href="">Read More</a>
                </div>
                <div class="col-sm-6">
                  <div class="d-flex align-items-center">
                    <img class="rounded-circle flex-shrink-0" src="img/user.jpg" alt="" style={{width: 45, height: 45}} />
                    <div class="ms-3">
                      <h6 class="text-primary mb-1">Doreen Abwola</h6>
                      <small>CEO & Founder</small>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-lg-6 about-img wow fadeInUp" data-wow-delay="0.5s">
              <div class="row">
                <div class="col-12 text-center">
                  <img class="img-fluid w-75 rounded-circle bg-light p-3" src={about1} alt="" />
                </div>
                <div class="col-6 text-start" style={{marginTop: -150}}>
                  <img class="img-fluid w-100 rounded-circle bg-light p-3" src={about2} alt="" />
                </div>
                <div class="col-6 text-end" style={{ marginTop: -150 }}>
                  <img class="img-fluid w-100 rounded-circle bg-light p-3" src={about3} alt="" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <!-- About End --> */}


      {/* <!-- Call To Action Start --> */}
      <div class="container-xxl py-5">
        <div class="container">
          <div class="bg-light rounded">
            <div class="row g-0">
              <div class="col-lg-6 wow fadeIn" data-wow-delay="0.1s" style={{ minHeight: 400 }}>
                <div class="position-relative h-100">
                  <img class="position-absolute w-100 h-100 rounded" src={calltoaction} style={{objectFit: 'cover'}} />
                </div>
              </div>
              <div class="col-lg-6 wow fadeIn" data-wow-delay="0.5s">
                <div class="h-100 d-flex flex-column justify-content-center p-5">
                  <h1 class="mb-4">Become A Member of our community</h1>
                  <p class="mb-4">Not only are gaurdians welcome, neighbours, well-wishers and their pets😁 can join our whatsapp channel. inachukua jamii kumlea mtoto</p>
                  <a class="btn btn-primary py-3 px-5" href="">Join Now<i class="fa fa-arrow-right ms-2"></i></a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <!-- Call To Action End --> */}


      {/* <!-- Classes Start --> */}
      <div class="container-xxl py-5">
        <div class="container">
          <div class="text-center mx-auto mb-5 wow fadeInUp" data-wow-delay="0.1s" style={{ maxWidth: 600 }}>
            <h1 class="mb-3">Fees Structure</h1>
            <p>The total amount for a full school uniform including a sweater, trouser/skirt, shirt/blouse, sportswear & stockings is UGX 150,000 only</p>
          </div>
          <div class="row g-4">
            <div class="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.1s">
              <div class="classes-item">
                <div class="bg-light rounded-circle w-75 mx-auto p-3">
                  <img class="img-fluid rounded-circle" src="img/classes-1.jpg" alt="" />
                </div>
                <div class="bg-light rounded p-4 pt-5 mt-n5">
                  <a class="d-block text-center h3 mt-3 mb-4" href="">Day Care</a>
                  <div class="d-flex align-items-center justify-content-center mb-4">
                    {/* <div class="d-flex align-items-center">
                      <img class="rounded-circle flex-shrink-0" src="img/user.jpg" alt="" style={{ width: 45, height: 45 }} />
                      <div class="ms-3">
                        <h6 class="text-primary mb-1">Jhon Doe</h6>
                        <small>Teacher</small>
                      </div>
                    </div> */}
                    <span class="bg-primary text-white rounded-pill py-2 px-3" href="">UGX 20,000</span>
                  </div>
                  <div class="row g-1">
                    <div class="col-4">
                      <div class="border-top border-3 border-primary pt-2">
                        <h6 class="text-primary mb-1">Age:</h6>
                        <small>3-5 Years</small>
                      </div>
                    </div>
                    <div class="col-4">
                      <div class="border-top border-3 border-success pt-2">
                        <h6 class="text-success mb-1">Time:</h6>
                        <small>Daily</small>
                      </div>
                    </div>
                    <div class="col-4">
                      <div class="border-top border-3 border-warning pt-2">
                        <h6 class="text-warning mb-1">Capacity:</h6>
                        <small>30 Kids</small>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.5s">
              <div class="classes-item">
                <div class="bg-light rounded-circle w-75 mx-auto p-3">
                  <img class="img-fluid rounded-circle" src="img/classes-1.jpg" alt="" />
                </div>
                <div class="bg-light rounded p-4 pt-5 mt-n5">
                  <a class="d-block text-center h3 mt-3 mb-4" href="">Day Care</a>
                  <div class="d-flex align-items-center justify-content-center mb-4">
                    {/* <div class="d-flex align-items-center">
                      <img class="rounded-circle flex-shrink-0" src="img/user.jpg" alt="" style={{ width: 45, height: 45 }} />
                      <div class="ms-3">
                        <h6 class="text-primary mb-1">Jhon Doe</h6>
                        <small>Teacher</small>
                      </div>
                    </div> */}
                    <span class="bg-primary text-white rounded-pill py-2 px-3" href="">UGX 105,000</span>
                  </div>
                  <div class="row g-1">
                    <div class="col-4">
                      <div class="border-top border-3 border-primary pt-2">
                        <h6 class="text-primary mb-1">Age:</h6>
                        <small>3-5 Years</small>
                      </div>
                    </div>
                    <div class="col-4">
                      <div class="border-top border-3 border-success pt-2">
                        <h6 class="text-success mb-1">Time:</h6>
                        <small>Weekly</small>
                      </div>
                    </div>
                    <div class="col-4">
                      <div class="border-top border-3 border-warning pt-2">
                        <h6 class="text-warning mb-1">Capacity:</h6>
                        <small>30 Kids</small>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.5s">
              <div class="classes-item">
                <div class="bg-light rounded-circle w-75 mx-auto p-3">
                  <img class="img-fluid rounded-circle" src="img/classes-1.jpg" alt="" />
                </div>
                <div class="bg-light rounded p-4 pt-5 mt-n5">
                  <a class="d-block text-center h3 mt-3 mb-4" href="">Day Care</a>
                  <div class="d-flex align-items-center justify-content-center mb-4">
                    {/* <div class="d-flex align-items-center">
                      <img class="rounded-circle flex-shrink-0" src="img/user.jpg" alt="" style={{ width: 45, height: 45 }} />
                      <div class="ms-3">
                        <h6 class="text-primary mb-1">Jhon Doe</h6>
                        <small>Teacher</small>
                      </div>
                    </div> */}
                    <span class="bg-primary text-white rounded-pill py-2 px-3" href="">UGX 450,000</span>
                  </div>
                  <div class="row g-1">
                    <div class="col-4">
                      <div class="border-top border-3 border-primary pt-2">
                        <h6 class="text-primary mb-1">Age:</h6>
                        <small>3-5 Years</small>
                      </div>
                    </div>
                    <div class="col-4">
                      <div class="border-top border-3 border-success pt-2">
                        <h6 class="text-success mb-1">Time:</h6>
                        <small>Monthly</small>
                      </div>
                    </div>
                    <div class="col-4">
                      <div class="border-top border-3 border-warning pt-2">
                        <h6 class="text-warning mb-1">Capacity:</h6>
                        <small>30 Kids</small>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.3s">
              <div class="classes-item">
                <div class="bg-light rounded-circle w-75 mx-auto p-3">
                  <img class="img-fluid rounded-circle" src="img/classes-2.jpg" alt="" />
                </div>
                <div class="bg-light rounded p-4 pt-5 mt-n5">
                  <a class="d-block text-center h3 mt-3 mb-4" href="">Nusery</a>
                  <div class="d-flex align-items-center justify-content-center mb-4">
                    {/* <div class="d-flex align-items-center">
                      <img class="rounded-circle flex-shrink-0" src="img/user.jpg" alt="" style={{ width: 45, height: 45 }} />
                      <div class="ms-3">
                        <h6 class="text-primary mb-1">Jhon Doe</h6>
                        <small>Teacher</small>
                      </div>
                    </div> */}
                    <span class="bg-primary text-white rounded-pill py-2 px-3" href="">UGX 550,000</span>
                  </div>
                  <div class="row g-1">
                    <div class="col-4">
                      <div class="border-top border-3 border-primary pt-2">
                        <h6 class="text-primary mb-1">Age:</h6>
                        <small>3-5 Years</small>
                      </div>
                    </div>
                    <div class="col-4">
                      <div class="border-top border-3 border-success pt-2">
                        <h6 class="text-success mb-1">Time:</h6>
                        <small>Half Day</small>
                      </div>
                    </div>
                    <div class="col-4">
                      <div class="border-top border-3 border-warning pt-2">
                        <h6 class="text-warning mb-1">Capacity:</h6>
                        <small>30 Kids</small>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.3s">
              <div class="classes-item">
                <div class="bg-light rounded-circle w-75 mx-auto p-3">
                  <img class="img-fluid rounded-circle" src="img/classes-2.jpg" alt="" />
                </div>
                <div class="bg-light rounded p-4 pt-5 mt-n5">
                  <a class="d-block text-center h3 mt-3 mb-4" href="">Nusery</a>
                  <div class="d-flex align-items-center justify-content-center mb-4">
                    {/* <div class="d-flex align-items-center">
                      <img class="rounded-circle flex-shrink-0" src="img/user.jpg" alt="" style={{ width: 45, height: 45 }} />
                      <div class="ms-3">
                        <h6 class="text-primary mb-1">Jhon Doe</h6>
                        <small>Teacher</small>
                      </div>
                    </div> */}
                    <span class="bg-primary text-white rounded-pill py-2 px-3" href="">UGX 650,000</span>
                  </div>
                  <div class="row g-1">
                    <div class="col-4">
                      <div class="border-top border-3 border-primary pt-2">
                        <h6 class="text-primary mb-1">Age:</h6>
                        <small>3-5 Years</small>
                      </div>
                    </div>
                    <div class="col-4">
                      <div class="border-top border-3 border-success pt-2">
                        <h6 class="text-success mb-1">Time:</h6>
                        <small>Full Day</small>
                      </div>
                    </div>
                    <div class="col-4">
                      <div class="border-top border-3 border-warning pt-2">
                        <h6 class="text-warning mb-1">Capacity:</h6>
                        <small>30 Kids</small>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.5s">
              <div class="classes-item">
                <div class="bg-light rounded-circle w-75 mx-auto p-3">
                  <img class="img-fluid rounded-circle" src={classes3} alt="" />
                </div>
                <div class="bg-light rounded p-4 pt-5 mt-n5">
                  <a class="d-block text-center h3 mt-3 mb-4" href="">Primary One</a>
                  <div class="d-flex align-items-center justify-content-center mb-4">
                    {/* <div class="d-flex align-items-center">
                      <img class="rounded-circle flex-shrink-0" src="img/user.jpg" alt="" style={{ width: 45, height: 45 }} />
                      <div class="ms-3">
                        <h6 class="text-primary mb-1">Jhon Doe</h6>
                        <small>Teacher</small>
                      </div>
                    </div> */}
                    <span class="bg-primary text-white rounded-pill py-2 px-3" href="">UGX 650,000</span>
                  </div>
                  <div class="row g-1">
                    <div class="col-4">
                      <div class="border-top border-3 border-primary pt-2">
                        <h6 class="text-primary mb-1">Age:</h6>
                        <small>3-5 Years</small>
                      </div>
                    </div>
                    <div class="col-4">
                      <div class="border-top border-3 border-success pt-2">
                        <h6 class="text-success mb-1">Time:</h6>
                        <small>Full Day</small>
                      </div>
                    </div>
                    <div class="col-4">
                      <div class="border-top border-3 border-warning pt-2">
                        <h6 class="text-warning mb-1">Capacity:</h6>
                        <small>30 Kids</small>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.1s">
              <div class="classes-item">
                <div class="bg-light rounded-circle w-75 mx-auto p-3">
                  <img class="img-fluid rounded-circle" src={classes4} alt="" />
                </div>
                <div class="bg-light rounded p-4 pt-5 mt-n5">
                  <a class="d-block text-center h3 mt-3 mb-4" href="">Primary Two</a>
                  <div class="d-flex align-items-center justify-content-center mb-4">
                    {/* <div class="d-flex align-items-center">
                      <img class="rounded-circle flex-shrink-0" src="img/user.jpg" alt="" style={{ width: 45, height: 45 }} />
                      <div class="ms-3">
                        <h6 class="text-primary mb-1">Jhon Doe</h6>
                        <small>Teacher</small>
                      </div>
                    </div> */}
                    <span class="bg-primary text-white rounded-pill py-2 px-3" href="">UGX 650,000</span>
                  </div>
                  <div class="row g-1">
                    <div class="col-4">
                      <div class="border-top border-3 border-primary pt-2">
                        <h6 class="text-primary mb-1">Age:</h6>
                        <small>3-5 Years</small>
                      </div>
                    </div>
                    <div class="col-4">
                      <div class="border-top border-3 border-success pt-2">
                        <h6 class="text-success mb-1">Time:</h6>
                        <small>Full Day</small>
                      </div>
                    </div>
                    <div class="col-4">
                      <div class="border-top border-3 border-warning pt-2">
                        <h6 class="text-warning mb-1">Capacity:</h6>
                        <small>30 Kids</small>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.3s">
              <div class="classes-item">
                <div class="bg-light rounded-circle w-75 mx-auto p-3">
                  <img class="img-fluid rounded-circle" src={classes5} alt="" />
                </div>
                <div class="bg-light rounded p-4 pt-5 mt-n5">
                  <a class="d-block text-center h3 mt-3 mb-4" href="">Primary Three</a>
                  <div class="d-flex align-items-center justify-content-center mb-4">
                    {/* <div class="d-flex align-items-center">
                      <img class="rounded-circle flex-shrink-0" src="img/user.jpg" alt="" style={{ width: 45, height: 45 }} />
                      <div class="ms-3">
                        <h6 class="text-primary mb-1">Jhon Doe</h6>
                        <small>Teacher</small>
                      </div>
                    </div> */}
                    <span class="bg-primary text-white rounded-pill py-2 px-3" href="">UGX 650,000</span>
                  </div>
                  <div class="row g-1">
                    <div class="col-4">
                      <div class="border-top border-3 border-primary pt-2">
                        <h6 class="text-primary mb-1">Age:</h6>
                        <small>3-5 Years</small>
                      </div>
                    </div>
                    <div class="col-4">
                      <div class="border-top border-3 border-success pt-2">
                        <h6 class="text-success mb-1">Time:</h6>
                        <small>Full Day</small>
                      </div>
                    </div>
                    <div class="col-4">
                      <div class="border-top border-3 border-warning pt-2">
                        <h6 class="text-warning mb-1">Capacity:</h6>
                        <small>30 Kids</small>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <!-- Classes End --> */}


      {/* <!-- Appointment Start --> */}
      <div class="container-xxl py-5">
        <div class="container">
          <div class="bg-light rounded">
            <div class="row g-0">
              <div class="col-lg-6 wow fadeIn" data-wow-delay="0.1s">
                <div class="h-100 d-flex flex-column justify-content-center p-5">
                  <h1 class="mb-4">Make an Appointment</h1>
                  <form>
                    <div class="row g-3">
                      <div class="col-sm-6">
                        <div class="form-floating">
                          <input type="text" class="form-control border-0" id="gname" placeholder="Gurdian Name" />
                          <label for="gname">Guardian Name</label>
                        </div>
                      </div>
                      <div class="col-sm-6">
                        <div class="form-floating">
                          <input type="email" class="form-control border-0" id="gmail" placeholder="Gurdian Email" />
                          <label for="gmail">Guardian Email</label>
                        </div>
                      </div>
                      <div class="col-sm-6">
                        <div class="form-floating">
                          <input type="text" class="form-control border-0" id="cname" placeholder="Child Name" />
                          <label for="cname">Guardian Phone</label>
                        </div>
                      </div>
                      <div class="col-sm-6">
                        <div class="form-floating">
                          <input type="text" class="form-control border-0" id="cage" placeholder="Child Age" />
                          <label for="cage">Child Name</label>
                        </div>
                      </div>
                      <div class="col-12">
                        <div class="form-floating">
                          <textarea class="form-control border-0" placeholder="Leave a message here" id="message" style={{height: 100}}></textarea>
                          <label for="message">Message</label>
                        </div>
                      </div>
                      <div class="col-12">
                        <button class="btn btn-primary w-100 py-3" type="submit">Submit</button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
              <div class="col-lg-6 wow fadeIn" data-wow-delay="0.5s" style={{minHeight: 400}}>
                <div class="position-relative h-100">
                  <img class="position-absolute w-100 h-100 rounded" src={appointment} style={{objectFit: 'cover'}} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <!-- Appointment End --> */}


      {/* <!-- Team Start --> */}
      {/* <div class="container-xxl py-5">
        <div class="container">
          <div class="text-center mx-auto mb-5 wow fadeInUp" data-wow-delay="0.1s" style={{maxWidth: 600}}>
            <h1 class="mb-3">Teaching Stuff</h1>
            <p>Eirmod sed ipsum dolor sit rebum labore magna erat. Tempor ut dolore lorem kasd vero ipsum sit
              eirmod sit. Ipsum diam justo sed rebum vero dolor duo.</p>
          </div>
          <div class="row g-4">
            <div class="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.1s">
              <div class="team-item position-relative">
                <img class="img-fluid rounded-circle w-75" src="img/team-1.jpg" alt="" />
                <div class="team-text">
                  <h3>Full Name</h3>
                  <p>Designation</p>
                  <div class="d-flex align-items-center">
                    <a class="btn btn-square btn-primary mx-1" href=""><i class="fab fa-facebook-f"></i></a>
                    <a class="btn btn-square btn-primary  mx-1" href=""><i class="fab fa-twitter"></i></a>
                    <a class="btn btn-square btn-primary  mx-1" href=""><i class="fab fa-instagram"></i></a>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.3s">
              <div class="team-item position-relative">
                <img class="img-fluid rounded-circle w-75" src="img/team-2.jpg" alt="" />
                <div class="team-text">
                  <h3>Full Name</h3>
                  <p>Designation</p>
                  <div class="d-flex align-items-center">
                    <a class="btn btn-square btn-primary mx-1" href=""><i class="fab fa-facebook-f"></i></a>
                    <a class="btn btn-square btn-primary  mx-1" href=""><i class="fab fa-twitter"></i></a>
                    <a class="btn btn-square btn-primary  mx-1" href=""><i class="fab fa-instagram"></i></a>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.5s">
              <div class="team-item position-relative">
                <img class="img-fluid rounded-circle w-75" src="img/team-3.jpg" alt="" />
                <div class="team-text">
                  <h3>Full Name</h3>
                  <p>Designation</p>
                  <div class="d-flex align-items-center">
                    <a class="btn btn-square btn-primary mx-1" href=""><i class="fab fa-facebook-f"></i></a>
                    <a class="btn btn-square btn-primary  mx-1" href=""><i class="fab fa-twitter"></i></a>
                    <a class="btn btn-square btn-primary  mx-1" href=""><i class="fab fa-instagram"></i></a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div> */}
      {/* <!-- Team End --> */}


      {/* <!-- Testimonial Start --> */}
      <div class="container-xxl py-5">
        <div class="container">
          <div class="text-center mx-auto mb-5 wow fadeInUp" data-wow-delay="0.1s" style={{ maxWidth: 600 }}>
            <h1 class="mb-3">Our Clients Say!</h1>
            <p>See what parents are saying about their children's wonderful journey and experiences at our academy</p>
          </div>
          <div class="owl-carousel testimonial-carousel wow fadeInUp" data-wow-delay="0.1s">
            <div class="testimonial-item bg-light rounded p-5">
              <p class="fs-5">We couldn't be happier with our decision to enroll our child at pitter patter academy. The caring staff, engaging curriculum, and nurturing environment have truly made a difference in our child's development. We've seen tremendous growth in their social skills, creativity, and love for learning. Thank you for providing such a wonderful place for our little one to thrive!</p>
              <div class="d-flex align-items-center bg-white me-n5" style={{borderRadius: '50px 0 0 50px'}}>
                <img class="img-fluid flex-shrink-0 rounded-circle" src="img/testimonial-1.jpg" style={{width: 90,height: 90}} />
                <div class="ps-3">
                  <h3 class="mb-1">Ampiire Edgar</h3>
                  <span>Doctor</span>
                </div>
                <i class="fa fa-quote-right fa-3x text-primary ms-auto d-none d-sm-flex"></i>
              </div>
            </div>
            <div class="testimonial-item bg-light rounded p-5">
              <p class="fs-5">Choosing this academy was one of the best decisions we've made as parents. From the moment we walked in, we felt welcomed and confident in the care provided. The dedicated teachers go above & beyond to create a supportive and stimulating learning environment. Our child comes home every day excited to share what they've learned and eager to return the next day.</p>
              <div class="d-flex align-items-center bg-white me-n5" style={{ borderRadius: '50px 0 0 50px' }}>
                <img class="img-fluid flex-shrink-0 rounded-circle" src="img/testimonial-2.jpg" style={{width: 90,height: 90}} />
                <div class="ps-3">
                  <h3 class="mb-1">Namulindwa Prossy</h3>
                  <span>Banker</span>
                </div>
                <i class="fa fa-quote-right fa-3x text-primary ms-auto d-none d-sm-flex"></i>
              </div>
            </div>
            <div class="testimonial-item bg-light rounded p-5">
              <p class="fs-5">Our experience has been nothing short of exceptional. The staff's genuine love for children is evident in every interaction, and it's clear that they prioritize each child's individual needs and interests. We've watched our child blossom into a confident and curious learner, thanks to the enriching activities provided. We highly recommend to any parent seeking the best childhood education for their child</p>
              <div class="d-flex align-items-center bg-white me-n5" style={{ borderRadius: '50px 0 0 50px' }}>
                <img class="img-fluid flex-shrink-0 rounded-circle" src="img/testimonial-3.jpg" style={{width: 90,height: 90}} />
                <div class="ps-3">
                  <h3 class="mb-1">Okello Moses</h3>
                  <span>Business Man</span>
                </div>
                <i class="fa fa-quote-right fa-3x text-primary ms-auto d-none d-sm-flex"></i>
              </div>
            </div>
            <div class="testimonial-item bg-light rounded p-5">
              <p class="fs-5">As first-time parents, we were nervous about choosing the right school for our child. From the moment we toured the academy, we knew it was the perfect fit. The warm and welcoming atmosphere immediately put our minds at ease. Our child has thrived academically, socially, and emotionally under the guidance of the dedicated teachers and staff. We're grateful for the strong foundation.</p>
              <div class="d-flex align-items-center bg-white me-n5" style={{ borderRadius: '50px 0 0 50px' }}>
                <img class="img-fluid flex-shrink-0 rounded-circle" src="img/testimonial-3.jpg" style={{width: 90,height: 90}} />
                <div class="ps-3">
                  <h3 class="mb-1">Luwaga Chris</h3>
                  <span>Real Estate Profession</span>
                </div>
                <i class="fa fa-quote-right fa-3x text-primary ms-auto d-none d-sm-flex"></i>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <!-- Testimonial End --> */}


      {/* <!-- Footer Start --> */}
      <Footer />
      {/* <!-- Footer End --> */}


      <a href="#" class="btn btn-lg btn-primary btn-lg-square back-to-top"><i class="bi bi-arrow-up"></i></a>
    </div>
  )
}

export default Home
