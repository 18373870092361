import React from 'react'
import { Helmet } from "react-helmet";
import NavBar from '../components/NavBar'
import Footer from '../components/Footer';

const PrivacyPolicy = () => {
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Privacy Policy - Pitter Patter Academy</title>
        <link rel="canonical" href="https://pitterpatteracademy.info/privacy" />
        <meta property="og:title" content="Privacy Policy Pitter Patter Academy" />
        <meta property="og:description"
          content="Discover a nurturing environment where every child's journey begins.Our holistic approach to early childhood education fosters a love for learning, promotes social and emotional development, and lays a strong foundation for future success. Explore our enriching curriculum, dedicated educators, and state-of-the-art facilities today." />
        <meta property="og:image"
          content="%PUBLIC_URL%/img/logo1.jpg" />
        <meta property="og:url" content="https://pitterpatteracademy.info/privacy" />
        <meta name="keywords" content="pitter patter academy entebbe kawuku" />
        <link href="https://fonts.googleapis.com/css2?family=Outfit:wght@100;200;400;700&display=swap" rel="stylesheet" />

      </Helmet>
      <div class="bg-white p-0">
        <NavBar />
        {/* <!-- Page Header End --> */}
        <div class="py-5 page-header position-relative mb-5">
          <div class="container py-5">
            <h1 class="display-2 text-white animated slideInDown mb-4">Privacy Policy</h1>
            <nav aria-label="breadcrumb animated slideInDown">
              <ol class="breadcrumb">
                <li class="breadcrumb-item"><a href="#">Home</a></li>
                <li class="breadcrumb-item text-white active" aria-current="page">Privacy Policy</li>
              </ol>
            </nav>
          </div>
        </div>
        {/* <!-- Page Header End --> */}
        <Footer />
      </div>
    </>
  )
}

export default PrivacyPolicy
